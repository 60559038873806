.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

a {
	cursor: pointer;
}

.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.75) !important;
	z-index: 9999 !important;
}

.ReactModal__Content {
	width: 40%;
	position: relative !important;
	margin: auto !important;
}

.modal-content,
.modal-footer {
	border: none !important;
}

.modal-body {
	padding: 1rem 0 !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

#b-white {
	border: solid white 2px;
}
